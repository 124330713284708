import { FeatureData } from '@innedit/innedit';
import { SlotType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../../components/View';
import Form from '../../../../../../../containers/Espace/Form';
import params from '../../../../../../../params/produit/slot.json';
import TemplateEspace from '../../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../../utils/requireUser';

const PageEspaceProduitSlotUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, produitId, slotId },
}) => {
  const slotData = new FeatureData<SlotType>({
    espaceId,
    params,
    collectionName: 'slots',
    parentCollectionName: 'produits',
    parentId: produitId,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form<SlotType>
          displayAdd
          displayNavigation={false}
          displayView={false}
          docId={slotId}
          itemPathnamePrefix={`/espaces/${espaceId}/produits/${produitId}/slots/`}
          model={slotData}
          name="slot"
          params={{ produitId }}
          title="Créneau"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProduitSlotUpdate);
